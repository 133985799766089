<template>
    <div ref="search-offering">
        <zw-sidebar @shown="shown" :title="$t('offering.title.search_offering')">
            <div v-if="!loading" class="px-3 py-2">
                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="sales"
                                      columnsPrefix="common.column.sale."
                                      base-table="offering"
                                      :customSource="getThis()"
                                      :items="myProvider"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :column-params="{type}"
                                      :actions-list="getActions()"
                            >
                            </zw-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button :disabled="selected.length!=1" block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from "@/bundles/erika_common_bundle/mixins/table-date-filter";

export default {
    name: 'SearchOfferingModal',
    mixins: [commonSave, commonTable, tableDateFilter],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            type: 'kva',
            saveFilters: false,
            tableUniqKey: 'search',
        }
    },
    methods: {
        ...mapGetters('Sales', ['getSalesTable']),
        shown() {
            this.$set(this, 'selected', [])
            this.form = JSON.parse(JSON.stringify(this.defaultForm))

            this.loading = false
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(
                ctx,
                'Sales/fetchSales',
                'getSalesTable',
                {'lfFile': 'lfFile', 'invoiceFile': 'invoiceFile'},
            )
        },
        ctxAdditionalParams() {
            return {
                'type':  this.type
            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.callback(this.selected[0].id)
                    this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                } else {
                    this.showValidationError()
                }
            })
        },
        getActions() {
            return []
        }
    },
}
</script>